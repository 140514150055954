footer .footer-medium .container {
    width: 1150px;
    font-size: 1em;
}

@media screen and (max-width: 1200px) {
    footer .footer-medium .container {
        width: 100%;
    }
}

footer .footer-medium {
    background-color: #ffd452;
    padding-bottom: 50px;
}

div.footer-medium-textSection {
    padding-top: 31px;
}

div.footer-medium-contactSection {
    margin-top: 14px;
    margin-bottom: 40px;
    font-size: 85%;
}

div.footer-medium-textSection h3,
div.footer-medium-textSection p {
    font-family: "Microsoft Yahei", "微软雅黑", Tahoma, Helvetica, Arial, STXihei, "华文细黑", sans-serif;
    font-size: 90%;
}

div.footer-medium-textSection h3 {
    font-weight: bold;
    pointer-events: none;
}

div.footer-medium-textSection p {
    font-weight: normal;
}

div.footer-medium-textSection ul {
    padding: 0;
}

div.footer-medium-textSection ul li {
    margin-bottom: 5px;
}

div.footer-medium-textSection ul.kode-form-list li p {
    margin-bottom: 1px;
}

.footer-medium-textSection ul.kode-form-list li p a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.footer-medium-textSection ul.kode-form-list li p a.hvr-underline-reveal:before {
    height: 2px;
    background-color: black;
}

.footer-medium-textSection a:hover,
.footer-medium-textSection a:focus {
    color: black;
}


div.partners>div {
    height: 50px;
    float: left;
    margin: 10px 10px;
    width: 180px;
    cursor: pointer;
    background: url("/images/partners.png");
    filter: grayscale(100%);
}

div.partners>div:hover {
    filter: initial;
}

div.partners>div.partner1 {
    width: 141px;
    background-position: -20px -30px;
}

div.partners>div.partner2 {
    width: 160px;
    background-position: -200px -30px;
}

div.partners>div.partner3 {
    width: 170px;
    background-position: -400px -30px;
}

div.partners>div.partner4 {
    width: 200px;
    background-position: -580px -30px;
}

div.partners>div.partner5 {
    width: 210px;
    background-position: -795px -30px;
}

div.partners>div.partner6 {
    width: 120px;
    background-position: -1020px -30px;
}

div.partners>div.partner7 {
    width: 170px;
    background-position: -20px -115px;
}

div.partners>div.partner8 {
    width: 170px;
    background-position: -210px -115px;
}

div.partners>div.partner9 {
    background-position: -400px -115px;
}

div.partners>div.partner10 {
    width: 190px;
    background-position: -620px -115px;
}

div.partners>div.partner11 {
    width: 165px;
    background-position: -840px -115px;
}

div.partners>div.partner12 {
    width: 145px;
    background-position: -1010px -115px;
}

.contact-sprite {
    background: url("/images/contact-icons.png") no-repeat;
    display: inline-block;
}

.footer-medium-textSection ul.contacts li {
    width: 50px;
    height: 67px;
    filter: grayscale(100%);
}

.footer-medium-textSection ul.contacts li:hover {
    cursor: pointer;
    filter: initial;
}

.footer-medium-textSection li.contact-email {
    background-position: -39px -30px;
}

.footer-medium-textSection li.contact-wechat {
    background-position: -110px -32px;
    margin-left: 20px;
}

.footer-medium-contactSection p.contact-address span {
    background-position: -58px -5px;
    width: 12px;
    height: 17px;
}

.footer-medium-contactSection p.contact-email span {
    width: 16px;
    height: 12px;
    background-position: -32px -5px;
}

.footer-medium-contactSection p.contact-phone span {
    background-position: -5px -5px;
    width: 17px;
    height: 17px;
}